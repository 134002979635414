import { NavigationGuard } from 'vue-router/types/router';
import { UrlResolver } from '@/components/api/url-resolver';
import vuex from '@/store';
import Vue from 'vue';
import { Storage } from '@/components/storage';
import { PagesName } from '@/router/routes/pages-enum';
import { PayloadGetExists } from '@/store/modules/store/types';
import { ActionsStore } from '@/store/modules/store/enum';
import { QueryParamsInitialPage } from './validates-integration-and-page-to-load';

/**
 * Guarda de rota que inicia o processo de autenticação com a plataforma integradora.
 */
export const initializeAuth: NavigationGuard = async (to, from, next) => {
  try {
    const currentQuery: QueryParamsInitialPage = to.query;
    const platform = UrlResolver.getQueryPlatformByParams(currentQuery as Record<string, string>);

    Storage.set('paramsInitHub', { ...currentQuery, platform });
    const params = Storage.get('paramsInitHub');

    const { url } = currentQuery;
    if (url) Storage.set('url', url);

    const payloadGetExists: PayloadGetExists = {
      storeId: Number(params.store),
      platform: params.platform,
    };

    const hasValidStore = await vuex.dispatch(
      'store/' + ActionsStore.CHECK_IF_EXISTS,
      payloadGetExists
    );

    if (!hasValidStore) {
      return next({
        name: PagesName.LETS_START,
      });
    }

    const shouldAuthenticateWithPlatform = !vuex.getters.isAuthenticated;
    if (shouldAuthenticateWithPlatform) {
      window.location.href = UrlResolver.getAuthUrl();
      return;
    }

    return next({
      name: PagesName.AUTH,
    });
  } catch (e) {
    return next({ name: 'not-found' });
  }
};
