import { RouteConfig } from 'vue-router';
import { CatalogCreateOfferGuard } from '@/router/routes/legacy-routes/guards';
import { PagesName } from '@/router/routes/pages-enum';

const catalogLegacyRoutes: RouteConfig[] = [
  {
    path: 'catalog/offers/add',
    name: PagesName.LEGACY_ADD_OFFER,
    meta: {
      title: 'Incluir anúncios',
      flow: 'old',
    },
    beforeEnter: CatalogCreateOfferGuard,
    component: () => import('@/pages/catalog/offers/create-offer/create-offer.vue'),
    children: [
      {
        path: 'products',
        name: PagesName.LEGACY_ADD_OFFER_PRODUCTS,
        meta: {
          step: 0,
          title: 'Seleção de conta e produtos',
          description: 'Selecione uma conta da Shopee e os produtos que serão anunciados',
          flow: 'old',
        },
        component: () =>
          import('@/pages/catalog/offers/create-offer/select-product/select-product.vue'),
      },
      {
        path: 'review',
        name: PagesName.LEGACY_ADD_OFFER_REVIEW,
        meta: {
          step: 1,
          title: 'Revisão de anúncios',
          description:
            'Revise as informações dos seus anúncios para que seja anunciados corretamente',
          flow: 'old',
        },
        component: () =>
          import(
            '@/pages/catalog/offers/create-offer/reviews/shopee-offer-review/shopee-offer-review.vue'
          ),
      },
    ],
  },
  {
    path: 'catalog/pricing',
    name: PagesName.LEGACY_PRICING,
    meta: {
      title: 'Calculadora de preços',
      flow: 'old',
    },
    component: () =>
      import(
        '@/pages/catalog/pricing-calculator/edit-pricing-calculator/edit-pricing-calculator.vue'
      ),
  },
];

export default catalogLegacyRoutes;
