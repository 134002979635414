export enum GetterAccount {
  /**
   * Retorna uma lista de contas deve possuir alerta de precificação
   */
  HAS_PRICING_ALERT = 'HAS_PRICING_ALERT',

  /**
   * Retorna a conta pendente
   */
  PENDING_ACCOUNT = 'PENDING_ACCOUNT',
}

export enum MutationAccount {
  /**
   * Define se a listagem de contas deve exibir alerta de precificação
   *
   * @param hasAlert - indica se deve exibir o alerta
   */
  SET_PRICING_ALERT = 'SET_PRICING_ALERT',

  /**
   * Define a conta pendente
   *
   * @param account - conta pendente
   */
  SET_PENDING_ACCOUNT = 'SET_PENDING_ACCOUNT',
}
