import { ActionTree } from 'vuex';
import { PublishedOffersListState } from './types';
import { RootState } from '@/store/types';
import { ActionListPublishedOffers } from './enum-action';
import { MutationListPublishedOffers } from './enum-mutation';
import { MutationPagingByRoute } from '../../paging-by-route/enum-mutation';
import Vue from 'vue';
import { ApiResponse } from '@tray-libs/design-system-components/src/components/api/client/http/response';
import { ParamsSetPaging } from '../../paging-by-route/types';
import { ApiPaging } from '@/components/api/client/http';
import Offer from '@/entities/offer-new';
import { PagesName } from '@/router/routes/pages-enum';
import { MutationFilterByRoute } from '../../filter-by-route/enum-mutation';
import { emptyFiltersPublishedOffers } from '.';
import { PricingRule } from '@/entities';
import {
  ConfigPricingRuleToSaveAPI,
  PayloadPricingRuleRequest,
  PricingRuleBatchesPayload,
} from '@/components/pricing-rule/type';
import { TypePricingScope } from '@/entities/pricing-rule';
import { HubApiResponse } from '@/components/api/hub-client';
import { GetterListPublishedOffers } from './enum-getter';
import { AxiosError } from 'axios';

export const actions: ActionTree<PublishedOffersListState, RootState> = {
  async [ActionListPublishedOffers.GET_LIST]({ commit }, params: URLSearchParams): Promise<any> {
    try {
      commit(MutationListPublishedOffers.SET_LOADING, true);
      const apiUrl = '/offers';

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<Offer[], ApiResponse>(apiUrl, {
        params,
      });

      const data = response.getData().map((item: Offer) => {
        const offer = Offer.from(item);
        if (!offer.offer_pricing) {
          offer.offer_pricing = null;
          return offer;
        }

        const pricing = PricingRule.from(offer.offer_pricing);

        offer.offer_pricing = pricing;
        return offer;
      });

      commit(MutationListPublishedOffers.SAVE_LIST, data);

      const paramsPaging: ParamsSetPaging = {
        route: PagesName.PUBLISHED_OFFERS,
        paging: response.getPaging() as ApiPaging,
      };
      commit('paging/' + MutationPagingByRoute.SAVE_PAGING, paramsPaging, {
        root: true,
      });

      return response;
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        { where: 'Action GET LIST Offers', error },
        { root: true }
      );
      throw error;
    } finally {
      commit(MutationListPublishedOffers.SET_LOADING, false);
    }
  },

  [ActionListPublishedOffers.RESET_FILTERS]({ commit }) {
    commit(
      'filter/' + MutationFilterByRoute.SAVE_FILTER,
      {
        route: PagesName.PUBLISHED_OFFERS,
        filters: { ...emptyFiltersPublishedOffers },
      },
      {
        root: true,
      }
    );
  },

  async [ActionListPublishedOffers.SAVE_NEW_PRICING_RULE](
    { commit, rootGetters, getters },
    pricingConfig: ConfigPricingRuleToSaveAPI
  ): Promise<void> {
    try {
      const storeId = rootGetters['store/store'].id;
      const marketplaceId = rootGetters['marketplace/activeMarketplace'].id;

      const listIds = getters[GetterListPublishedOffers.PRICING_IDS_OFFER];
      if (!listIds) {
        throw new Error('ID of offer not found');
      }

      const pricingToSave: PayloadPricingRuleRequest = {
        ...pricingConfig,
        scope_type: TypePricingScope.OFFER,
        scope_ids: listIds,
        marketplace_id: marketplaceId,
      };

      const client = Vue.$apiClient.getHttpClient();
      await client.post<unknown, HubApiResponse>(`/stores/${storeId}/pricing`, pricingToSave);

      commit(MutationListPublishedOffers.RESET_PRICING);

      commit(
        'uiSettings/setNotification',
        {
          title: 'Regra de precificação salva com sucesso!',
          message: 'Pode levar algum tempo para que a alteração seja aplicada ao anúncio.',
          variant: 'success',
          autoHideDelay: 5000,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
    } catch (e) {
      const error: AxiosError = new AxiosError(
        'Não foi possível salvar a regra de precificação para o anúncio! Tente novamente mais tarde.'
      );
      error.code = '1001';
      commit(
        'uiSettings/setNotification',
        {
          where: 'Action Save new Pricing by offer',
          error,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );

      throw e;
    }
  },

  async [ActionListPublishedOffers.UPDATE_PRICING_RULE](
    { commit, rootGetters, getters },
    pricingConfig: ConfigPricingRuleToSaveAPI
  ): Promise<void> {
    try {
      const storeId = rootGetters['store/store'].id;
      const marketplaceId = rootGetters['marketplace/activeMarketplace'].id;

      const listIds = getters[GetterListPublishedOffers.PRICING_IDS_OFFER];
      const idPricingToEdit = getters[GetterListPublishedOffers.PRICING_TO_EDIT]?.id;

      if (!listIds || !idPricingToEdit) {
        throw new Error('ID of offer or pricing not found');
      }

      const pricingToSave: PayloadPricingRuleRequest = {
        ...pricingConfig,
        scope_ids: listIds,
        marketplace_id: marketplaceId,
      };

      const client = Vue.$apiClient.getHttpClient();
      await client.put<unknown, HubApiResponse>(
        `/stores/${storeId}/pricing/${idPricingToEdit}`,
        pricingToSave
      );

      commit(MutationListPublishedOffers.RESET_PRICING);

      commit(
        'uiSettings/setNotification',
        {
          title: 'Regra de precificação salva com sucesso!',
          message: 'A alteração foi aplicada ao anúncio.',
          variant: 'success',
          autoHideDelay: 5000,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
    } catch (e) {
      const error: AxiosError = new AxiosError(
        'Não foi possível salvar a regra de precificação para o anúncio! Tente novamente mais tarde.'
      );
      error.code = '1001';
      commit(
        'uiSettings/setNotification',
        {
          where: 'Action update Pricing by offer',
          error,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
      throw e;
    }
  },

  async [ActionListPublishedOffers.DELETE_PRICING_RULE]({
    commit,
    rootGetters,
    getters,
  }): Promise<void> {
    try {
      const storeId = rootGetters['store/store'].id;

      const idPricingToEdit = getters[GetterListPublishedOffers.PRICING_TO_EDIT]?.id;
      if (!idPricingToEdit) {
        throw new Error('ID of pricing not found');
      }

      const client = Vue.$apiClient.getHttpClient();
      await client.delete<unknown, HubApiResponse>(`/stores/${storeId}/pricing/${idPricingToEdit}`);

      commit(MutationListPublishedOffers.RESET_PRICING);

      commit(
        'uiSettings/setNotification',
        {
          title: 'Regra de precificação salva com sucesso!',
          message: 'Pode levar algum tempo para que a alteração seja aplicada ao anúncio.',
          variant: 'success',
          autoHideDelay: 5000,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
    } catch (e) {
      const error: AxiosError = new AxiosError(
        'Não foi possível salvar a regra de precificação para o anúncio! Tente novamente mais tarde.'
      );
      error.code = '1001';
      commit(
        'uiSettings/setNotification',
        {
          where: 'Action delete Pricing by offer',
          error,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );

      throw e;
    }
  },

  async [ActionListPublishedOffers.SAVE_MULTIPLES_OFFERS_PRICING_RULE](
    { commit, rootGetters, getters },
    pricingConfig: ConfigPricingRuleToSaveAPI
  ): Promise<void> {
    try {
      const storeId = rootGetters['store/store'].id;
      const marketplaceId = rootGetters['marketplace/activeMarketplace'].id;

      const listIds = getters[GetterListPublishedOffers.PRICING_IDS_OFFER];
      if (!listIds) {
        throw new Error('ID of offer not found');
      }

      const payloadToSave: PricingRuleBatchesPayload = {
        offers: listIds,
        offer_pricing: pricingConfig,
      };

      const client = Vue.$apiClient.getHttpClient();
      await client.post<unknown, HubApiResponse>(
        `/stores/${storeId}/pricing-batches`,
        payloadToSave
      );

      commit(MutationListPublishedOffers.RESET_PRICING);

      commit(
        'uiSettings/setNotification',
        {
          title: 'Regra de precificação salva com sucesso!',
          message: 'Pode levar algum tempo para que a alteração seja aplicada a todos os anúncios.',
          variant: 'success',
          autoHideDelay: 5000,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
    } catch (e) {
      const error: AxiosError = new AxiosError(
        'Não foi possível salvar a regra de precificação para o anúncio! Tente novamente mais tarde.'
      );
      error.code = '1001';
      commit(
        'uiSettings/setNotification',
        {
          where: 'Action Save new Pricing by offer',
          error,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
      throw e;
    }
  },
};

export default actions;
