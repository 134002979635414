export enum Actions {
  /**
   * Busca todas as Categorias raiz do marketplace ativo.
   */
  GET_CATEGORIES_ROOT = 'GET_CATEGORIES_ROOT',

  /**
   * Busca uma categoria pelo ref do marketplace ativo.
   */
  GET_CATEGORY_MARKETPLACE_BY_REF = 'GET_CATEGORY_MARKETPLACE_BY_REF',

  /**
   *
   */
  POST_BINDING_ATTRIBUTE = 'POST_BINDING_ATTRIBUTE',

  /**
   * Salva o relacionamento da categoria e características entre plataforma e
   * marketplace.
   */
  POST_BINDING_CATEGORY = 'POST_BINDING_CATEGORY',

  GET_CATEGORY_PLATFORM_BY_REF = 'GET_CATEGORY_PLATFORM_BY_REF',

  /**
   * Busca a listagem de categorias completa para listagem.
   */
  GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST',

  /**
   * Limpa todos os dados de categorias em relacionamento
   */
  CLEAR_ALL_OF_CATEGORY_BINDING = 'CLEAR_ALL_OF_CATEGORY_BINDING',

  /**
   * Limpa todos os dados de categoria/características da sidebar de relacionamento
   */
  CLEAR_ALL_OF_CATEGORY_ATTRIBUTES_BINDING = 'CLEAR_ALL_OF_CATEGORY_ATTRIBUTES_BINDING',

  // =============> Category-Attributes

  /**
   * Busca os atributos do marketplace ativo por ref da categoria em relacionamento.
   */
  GET_ATTRIBUTES_MARKETPLACE = 'GET_ATTRIBUTES_MARKETPLACE',

  /**
   * Busca os valores dos atributos do marketplace
   */
  GET_ATTRIBUTES_VALUES_MARKETPLACE = 'GET_ATTRIBUTES_VALUES_MARKETPLACE',

  /**
   * Busca os atributos da plataforma ativa
   */
  GET_ATTRIBUTES_PLATFORM = 'GET_ATTRIBUTES_PLATFORM',

  /**
   * Busca os valores dos atributos da plataforma ativa
   */
  GET_ATTRIBUTES_VALUES_PLATFORM = 'GET_ATTRIBUTES_VALUES_PLATFORM',

  /**
   * Busca o relacionamento entre atributos da plataforma e do marketplace, salvando
   * todos os values nas store no formato necessário.
   * Deve ser executado quando a característica já tiver relacionada e for do tipo que
   * contém valores
   */
  GET_ATTRIBUTES_VALUES_BINDING = 'GET_ATTRIBUTES_VALUES_BINDING',

  /**
   * Define a listagem de categorias selecionadas para criação de ofertas.
   */
  SET_CATEGORIES_LIST_CREATE_OFFERS = 'SET_CATEGORIES_LIST_CREATE_OFFERS',

  /**
   * Valida se os valores e as unidades das característica vinculadas do marketplace
   * ainda estão registrados ou se foram excluídos para exibir o alerta de notificação,
   * e atualiza a label do valor com a atual label obtida da API dos valores do atributo.
   */
  VALIDATE_IF_EXISTS_ATTRIBUTES_VALUES_AND_UPDATE_LABEL = 'VALIDATE_IF_EXISTS_ATTRIBUTES_VALUES_AND_UPDATE_LABEL',

  /**
   * Valida se todas as características vinculadas do marketplace ainda estão registradas
   * ou se foram excluídas para exibir o alerta de notificação e remover o relacionamento
   * no front.
   */
  VALIDATE_IF_EXISTS_ATTRIBUTES = 'VALIDATE_IF_EXISTS_ATTRIBUTES',
}

export enum Mutations {
  /**
   * Prepara os dados de `path_from_root` e `fullpath` para a categoria linkada
   * ser salva sem problemas.
   */
  PREPARES_DATA_FOR_LINKED_CATEGORY = 'PREPARES_DATA_FOR_LINKED_CATEGORY',

  /**
   * Define uma categoria da plataforma como ativa para relacionamento.
   */
  SET_CATEGORY_PLATFORM_IN_BINDING = 'SET_CATEGORY_PLATFORM_IN_BINDING',

  /**
   * Define uma categoria do marketplace como selecionada para relacionamento.
   */
  SET_CATEGORY_MARKETPLACE_IN_BINDING = 'SET_CATEGORY_MARKETPLACE_IN_BINDING',

  /**
   * Salva todas as categorias raiz do marketplace ativo.
   */
  SAVE_CATEGORIES_MARKETPLACE_ROOT = 'SAVE_CATEGORIES_MARKETPLACE_ROOT',

  /**
   * Salva a partir do Id de uma categoria todas suas subcategorias do marketplace ativo.
   */
  SAVE_CATEGORIES_MARKETPLACE_BY_REF = 'SAVE_CATEGORIES_MARKETPLACE_BY_REF',

  /**
   * Limpa todas as categorias em relacionamento do marketplace e plataforma que são u
   * usados na sidebar de relacionamento de categoria
   */
  CLEAR_CATEGORIES_BINDING = 'CLEAR_CATEGORIES_BINDING',

  /**
   * Limpa todas categorias do marketplace e plataforma, e seus valores que são usados
   * na sidebar de seleção
   */
  CLEAR_CATEGORIES_SELECTION = 'CLEAR_CATEGORIES_SELECTION',

  UPDATE_CATEGORY_IN_LIST = 'UPDATE_CATEGORY_IN_LIST',

  /**
   * Salva as categorias que serão exibida na lista de navegação para relacionamento do marketplace ativo.
   */
  SAVE_CATEGORIES_MARKETPLACE_IN_BINDING = 'SAVE_CATEGORIES_MARKETPLACE_IN_BINDING',

  /**
   * Define carregamento das categorias do marketplace ativo em relacionamento
   */
  SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING = 'SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING',

  /**
   * Define o caminho da categoria do marketplace ativo em relacionamento
   */
  SET_CATEGORIES_MARKETPLACE_PATH_IN_BINDING = 'SET_CATEGORIES_MARKETPLACE_PATH_IN_BINDING',

  /**
   * Carrega a lista de categorias do marketplace ativo em relacionamento clicado para navegar
   */
  LOAD_CATEGORIES_MARKETPLACE_IN_BINDING_BY_REF = 'LOAD_CATEGORIES_MARKETPLACE_IN_BINDING_BY_REF',

  /**
   * Carrega a lista de categorias raiz do marketplace ativo em relacionamento
   */
  LOAD_CATEGORIES_ROOT_MARKETPLACE_IN_BINDING = 'LOAD_CATEGORIES_ROOT_MARKETPLACE_IN_BINDING',

  /**
   * Adiciona a categoria da plataform, ativa em relacionamento, para o objeto de
   * relacionados, preparando para envio a api
   */
  ADD_CATEGORY_PLATFORM_IN_BINDIG_TO_LINKED = 'ADD_CATEGORY_PLATFORM_IN_BINDIG_TO_LINKED',

  /**
   * Adiciona a categoria do marketplace ativo em relacionamento selecionada para o objeto de relacionados, preparando para envio a api
   */
  ADD_CATEGORY_MARKETPLACE_IN_BINDIG_TO_LINKED = 'ADD_CATEGORY_MARKETPLACE_IN_BINDIG_TO_LINKED',

  /**
   * Adiciona a categoria do marketplace ativo em relacionamento selecionada por ref
   * para o objeto de relacionados, preparando para envio a api
   */
  ADD_CATEGORY_MARKETPLACE_IN_BINDIG_TO_LINKED_BY_REF = 'ADD_CATEGORY_MARKETPLACE_IN_BINDIG_TO_LINKED_BY_REF',

  /**
   * Salva as categorias na lista
   */
  SAVE_LIST_CATEGORY = 'SAVE_LIST_CATEGORY',

  /**
   * Define o carregamento da listagem de categorias.
   */
  SET_LOADING_LIST_CATEGORY = 'SET_LOADING_LIST_CATEGORY',

  /**
   * Define o error a ser exibido na operação de relacionamento de categoria/característica
   */
  SET_ERROR_IN_BINDIG = 'SET_ERROR_IN_BINDIG',

  /**
   * Limpa a categoria do marketplace relacionada pronto para envio a api
   */
  CLEAR_CATEGORY_MARKETPLACE_LINKED = 'CLEAR_CATEGORY_MARKETPLACE_LINKED',

  // =============> Category-Attributes

  /**
   * Salva a lista de atributos do marketplace ativo em relacionamento obtida por categoria
   */
  SAVE_ATTRIBUTES_MARKETPLACE_BY_CATEGORY = 'SAVE_ATTRIBUTES_MARKETPLACE_BY_CATEGORY',

  /**
   * Salva a lista de atributos da plataforma em relacionamento
   */
  SAVE_ATTRIBUTES_PLATFORM_IN_BINDING = 'SAVE_ATTRIBUTES_PLATFORM_IN_BINDING',

  /**
   * Salva a lista de todos os atributos da plataforma em relacionamento
   */
  SAVE_ALL_ATTRIBUTES_PLATFORM_IN_BINDING = 'SAVE_ALL_ATTRIBUTES_PLATFORM_IN_BINDING',

  /**
   * Salva o atributo do marketplace para relacionar
   */
  SAVE_ATTRIBUTE_PLATFORM_IN_LINKED = 'SAVE_ATTRIBUTE_PLATFORM_IN_LINKED',

  /**
   * Salva a lista de valores dos atributos da plataforma obtidos para relacionar
   */
  SAVE_ATTRIBUTES_VALUES_PLATFORM_IN_BINDING = 'SAVE_ATTRIBUTES_VALUES_PLATFORM_IN_BINDING',

  /**
   * Salva a lista de valores dos atributos do marketplace obtidos para relacionar
   */
  SAVE_ATTRIBUTES_VALUES_MARKETPLACE_IN_BINDING = 'SAVE_ATTRIBUTES_VALUES_MARKETPLACE_IN_BINDING',

  /**
   * Salva a lista de atributos do marketplace e plataforma para relacionar
   */
  SAVE_ATTRIBUTES_VALUES_MARKETPLACE_FOR_BINDING = 'SAVE_ATTRIBUTES_VALUES_MARKETPLACE_FOR_BINDING',

  /**
   * Salva a lista de valores dos atributos da plataforma obtidos efetuando um pré
   * relacionamento para listar os valores na tabela
   */
  PRE_BINDING_ATTRIBUTES_VALUES_PLATFORM_IN_LINKED = 'PRE_BINDING_ATTRIBUTES_VALUES_PLATFORM_IN_LINKED',

  /**
   * Salva a lista de valores dos atributos do marketplace obtidos efetuando um pré
   * relacionamento para listar os valores na tabela
   */
  PRE_BINDING_ATTRIBUTES_VALUES_MARKETPLACE_IN_LINKED = 'PRE_BINDING_ATTRIBUTES_VALUES_MARKETPLACE_IN_LINKED',

  /**
   * Salva o atributo `changed` para o valor definido, indicando que houve alteração e
   * algo foi relacionado na sidebar de relacionamento, permitindo salvar.
   */
  SET_LINKED_CHANGED_TO = 'SET_LINKED_CHANGED_TO',

  /**
   * Salva os valores dos atributos já relacionados na vuex para exibição na listagem de relacionamento
   */
  SAVE_ATTRIBUTES_VALUES_BINDING_IN_LINKED = 'SAVE_ATTRIBUTES_VALUES_BINDING_IN_LINKED',

  /**
   * Salva os atributo do marketplace e plataforma para relacionar deixando os dados na
   * vuex de exibição, pronta para salvar na API
   */
  SAVE_ATTRIBUTES_BINDING_IN_LINKED = 'SAVE_ATTRIBUTES_BINDING_IN_LINKED',

  UPDATE_ATTRIBUTES_MARKETPLACE_IN_BINDING = 'UPDATE_ATTRIBUTES_MARKETPLACE_IN_BINDING',

  /**
   * Salva o valor do atributo do marketplace pelo índice indicado
   */
  SAVE_ATTRIBUTE_VALUE_MARKETPLACE_BY_INDEX = 'SAVE_ATTRIBUTE_VALUE_MARKETPLACE_BY_INDEX',

  /**
   * Selecionar um atributo do marketplace ativo em relacionamento para relacionar com
   * característica e valores da plataforma
   */
  SELECT_ATTRIBUTE_MARKETPLACE_IN_BINDIG = 'SELECT_ATTRIBUTE_MARKETPLACE_IN_BINDIG',

  /**
   * Limpa todas os atributos/características do marketplace e plataforma que são usados
   * na sidebar de relacionamento de categoria
   */
  CLEAR_ATTRIBUTES_BINDING = 'CLEAR_ATTRIBUTES_BINDING',

  /**
   * Limpa todas os atributos/características do marketplace e plataforma, e seus valores
   * que são usados na sidebar de seleção
   */
  CLEAR_ATTRIBUTES_SELECTION = 'CLEAR_ATTRIBUTES_SELECTION',

  // ==============> Página de ofertas
  /**
   * Salva as categorias selecionadas na lista.
   */
  SAVE_LIST_CATEGORIES_CREATE_OFFERS = 'SAVE_LIST_CATEGORIES_CREATE_OFFERS',
}

export enum Getters {
  /**
   * Retorna todas as categorias raiz de primeiro nível do marketplace ativo.
   */
  ROOT_OF_MARKETPLACE = 'ROOT_OF_MARKETPLACE',

  /**
   * Retorna todas as categorias do marketplace ativo por ref.
   */
  BY_REF_OF_MARKETPLACE = 'BY_REF_OF_MARKETPLACE',

  /**
   * Retorna a categoria selecionada do marketplace ativo em relacionamento ou null se
   * nada estiver selecionado.
   */
  SELECTED_CATEGORY_MARKETPLACE_IN_BINDING = 'SELECTED_CATEGORY_MARKETPLACE_IN_BINDING',

  /**
   * Categorias do marketplace ativas em relacionamento.
   */
  CATEGORIES_MARKETPLACE_IN_BINDING = 'CATEGORIES_MARKETPLACE_IN_BINDING',

  /**
   * Caminho de níveis da categoria do marketplace ativo em relacionamento.
   */
  PATH_CATEGORIES_MARKETPLACE_IN_BINDING = 'PATH_CATEGORIES_MARKETPLACE_IN_BINDING',

  /**
   * Categoria da plataforma ativa em relacionamento
   */
  CATEGORY_PLATFORM_IN_BINDING = 'CATEGORY_PLATFORM_IN_BINDING',

  /**
   * Valida se está carregando as categorias do marketplace ativo em relacionamento.
   */
  ISLOADING_CATEGORIES_MARKETPLACE_IN_BINDING = 'ISLOADING_CATEGORIES_MARKETPLACE_IN_BINDING',

  /**
   * Retorna o possível erro na operação de relacionamento de categoria/característica.
   */
  ERROR_IN_BINDING = 'ERROR_IN_BINDING',

  //==========> Category-Attributes
  /**
   * Retorna os atributos do marketplace opcionais para o relacionamento.
   */
  ATTRIBUTES_MARKETPLACE_OPTIONAL = 'ATTRIBUTES_MARKETPLACE_OPTIONAL',

  /**
   * Retorna os atributos do marketplace obrigatórios para o relacionamento.
   */
  ATTRIBUTES_MARKETPLACE_REQUIRED = 'ATTRIBUTES_MARKETPLACE_REQUIRED',

  /**
   * Retorna os valores dos atributos do marketplace selecionado para relacionar.
   */
  ATTRIBUTES_VALUES_MARKETPLACE_IN_BINDING = 'ATTRIBUTES_VALUES_MARKETPLACE_IN_BINDING',

  /**
   * Lista com todas as unidades de medida do atributo do marketplace selecionado para
   * relacionar no formato para uso no componente
   */
  UNITS_ATTRIBUTE_MARKETPLACE_IN_BINDING = 'UNITS_ATTRIBUTE_MARKETPLACE_IN_BINDING',

  /**
   * Retorna a caracteristica/atributo selecionada para relacionar com as características
   * e valores da plataforma.
   */
  SELECTED_ATTRIBUTE_MARKETPLACE_IN_BINDING = 'SELECTED_ATTRIBUTE_MARKETPLACE_IN_BINDING',

  /**
   * Retorna os atributos da plataforma em relacionamento.
   */
  ATTRIBUTES_PLATFORM_IN_BINDING = 'SAVE_ATTRIBUTES_PLATFORM_IN_BINDING',

  /**
   * Retorna todos os atributos relacionados da plataforma e marketplace.
   */
  ATTRIBUTES_LINKED_IN_BINDING = 'ATTRIBUTES_LINKED_IN_BINDING',

  /**
   * Retorna todos os valores de atributos relacionados da plataforma e marketplace.
   */
  ATTRIBUTES_VALUES_LINKED_IN_BINDING = 'ATTRIBUTES_VALUES_LINKED_IN_BINDING',

  /**
   * Lista com todos os valores de atributos da plataforma
   */
  ATTRIBUTES_VALUES_PLATFORM_IN_BINDING = 'ATTRIBUTES_VALUES_PLATFORM_IN_BINDING',

  /**
   * Retorna todos os atributos relacionados da plataforma e marketplace em uma lista
   * contendo objeto com chave `left` e `right` no formato para exibição na tabela.
   */
  ATTRIBUTES_VALUES_LEFT_RIGHT_LINKED_IN_BINDING = 'ATTRIBUTES_VALUES_LEFT_RIGHT_LINKED_IN_BINDING',

  /**
   * Retorna os dados da categoria e característica do marketplace e sua respectiva
   * categoria da plataforma selecionados para o relacionamento.
   */
  LINKED_CATEGORY_ATTRIBUTE_TO_BINDING = 'LINKED_CATEGORY_ATTRIBUTE_TO_BINDING',

  //==========> Page Category
  /**
   * Lista de categorias da plataforma e marquetplaces para adicionar e editar seus relacionamentos
   */
  CATEGORIES_LIST = 'CATEGORIES_LIST',
  /**
   * Paginação da listagem retornada pelo end-point.
   */
  CATEGORIES_LIST_PAGINATE = 'CATEGORIES_LIST_PAGINATE',
  /**
   * Salva se a listagem está carregando.
   */
  ISLOADING_CATEGORIES_LIST = 'ISLOADING_CATEGORIES_LIST',
  /**
   * Lista de categorias selecionadas para criação de oferta.
   */
  SELECTED_CATEGORIES_TO_OFFERS = 'SELECTED_CATEGORIES_TO_OFFERS',
}
