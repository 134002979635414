import { MutationTree } from 'vuex';
import { Category, Product } from '@/entities';
import { ConfigPricingRuleToSaveAPI, PricingConfigSummary } from '@/components/pricing-rule/type';

import { CreateOfferState, SaleChannel } from './types';
import { Mutations } from './enum';
import PricingRule from '@/entities/pricing-rule';

export const mutations: MutationTree<CreateOfferState> = {
  [Mutations.SET_SALE_CHANNEL](state, saleChannel: SaleChannel) {
    state.saleChannel = saleChannel;
  },

  [Mutations.SET_CATEGORIES_CREATE_OFFERS](state, categories: Array<Category>) {
    state.selectedCategories = categories;
  },

  [Mutations.ADD_CATEGORY_CREATE_OFFERS](state, category: Category) {
    state.selectedCategories.push(category);
  },

  [Mutations.REMOVE_CATEGORY_CREATE_OFFERS](state, category: Category) {
    state.selectedCategories.splice(state.selectedCategories.indexOf(category), 1);
  },

  [Mutations.SET_PRODUCTS_CREATE_OFFERS](state, products: Array<Product>) {
    state.selectedProducts = products;
  },

  [Mutations.ADD_PRODUCT_CREATE_OFFERS](state, product: Product) {
    state.selectedProducts.push(product);
  },

  [Mutations.REMOVE_PRODUCT_CREATE_OFFERS](state, product: Product) {
    state.selectedProducts.splice(state.selectedProducts.indexOf(product), 1);
  },

  [Mutations.SET_PRECIFICATION_ACCOUNT_SELECTED](state, precification: PricingRule) {
    state.pricing = precification;
  },

  [Mutations.SET_PRECIFICATION_EDIT](state, precification: PricingRule) {
    state.pricingEdit = precification;
  },

  [Mutations.SET_CONFIG_PRICING](state, config: ConfigPricingRuleToSaveAPI) {
    state.configPricingEdit = config;
  },

  [Mutations.SET_CONFIG_SUMMARY_CREATE_OFFER](state, config: PricingConfigSummary) {
    state.configPricingSummary = config;
  },

  [Mutations.SET_ERROR_LOAD_PRICING](state, status: boolean) {
    state.errorLoadPricing = status;
  },

  [Mutations.SET_SHOULD_CLEAR_SELECTED_CATEGORIES](state, clear: boolean) {
    state.shouldClearSelectedCategories = clear;

    setTimeout(() => {
      state.shouldClearSelectedCategories = false;
    }, 1000);
  },
};

export default mutations;
