import Vue from 'vue';
import { MutationTree } from 'vuex';
import { AttributeValue, CategoriesState, LinkAttributeMarketplaceValue } from './types';
import { Mutations } from './enum';
import CategoryAttribute from '@/entities/category-attributes';
import { ApiBindingValue } from '@/components/marketplace/api/types/api-binding';
import { TypeAttributeBinding } from '@/components/category/type-attribute-binding.enum';
import convertStringToFloat from '@/components/ui/relationship-table/convert-string-to-float';

export const mutationsAtttributes: MutationTree<CategoriesState> = {
  [Mutations.SAVE_ATTRIBUTES_MARKETPLACE_BY_CATEGORY](state, attributes: CategoryAttribute[]) {
    state.attributes.marketplace = attributes;
  },

  [Mutations.SAVE_ATTRIBUTES_PLATFORM_IN_BINDING](state, attributes: CategoryAttribute[]) {
    state.toBinding.platform.attributes = attributes;
  },

  [Mutations.SAVE_ALL_ATTRIBUTES_PLATFORM_IN_BINDING](state, attributes: CategoryAttribute[]) {
    state.toBinding.platform.allAttributes = attributes;
  },

  [Mutations.SAVE_ATTRIBUTES_VALUES_PLATFORM_IN_BINDING](
    state,
    attributesValues: AttributeValue[]
  ) {
    state.toBinding.platform.attributesValues = attributesValues;
  },

  [Mutations.SAVE_ATTRIBUTES_VALUES_MARKETPLACE_IN_BINDING](
    state,
    attributesValues: AttributeValue[]
  ) {
    state.toBinding.marketplace.attributesValues = attributesValues;
  },

  [Mutations.PRE_BINDING_ATTRIBUTES_VALUES_PLATFORM_IN_LINKED](state) {
    const preBinding: ApiBindingValue[] = state.toBinding.platform.attributesValues.map(
      (attribute) => {
        return {
          marketplace_attribute_value: {
            ref: '',
            label: '',
            value: '',
            unit: '',
            unit_symbol: '',
          },
          platform_attribute_value: {
            ref: attribute.ref,
            label: attribute.label,
            value: attribute.value,
          },
        };
      }
    );
    Vue.set(state.toBinding.linked.attributes, 'values', preBinding);
  },

  [Mutations.PRE_BINDING_ATTRIBUTES_VALUES_MARKETPLACE_IN_LINKED](state) {
    const valuesToCompare = state.toBinding.marketplace.attributesValues;

    const preBinding = state.toBinding.linked.attributes.values?.map(
      (attribute: ApiBindingValue) => {
        if (
          state.toBinding.linked.attributes.marketplace_attribute?.type ===
          TypeAttributeBinding.SELECT
        ) {
          const sameLabels = valuesToCompare.find((attributeMarketplace) => {
            const labelMarketplace = attributeMarketplace.label.toLowerCase();
            const labelPlatform = attribute.platform_attribute_value.label.toLowerCase();

            if (labelMarketplace === labelPlatform) {
              return attributeMarketplace;
            }
          });

          if (sameLabels) {
            return {
              platform_attribute_value: {
                ...attribute.platform_attribute_value,
              },
              marketplace_attribute_value: {
                ref: sameLabels.ref,
                label: sameLabels.label,
                value: sameLabels.value,
              },
            };
          }
        }

        return attribute;
      }
    );
    Vue.set(state.toBinding.linked.attributes, 'values', preBinding);
  },

  [Mutations.SAVE_ATTRIBUTE_PLATFORM_IN_LINKED](state, attribute: CategoryAttribute) {
    state.toBinding.linked.attributes.platform_attribute = {
      ref: attribute.ref,
      name: attribute.name,
      label: attribute.label,
      type: attribute.type,
    };
  },

  [Mutations.SAVE_ATTRIBUTES_VALUES_BINDING_IN_LINKED](state, values: ApiBindingValue[]) {
    const allValues = values.map((attribute) => {
      const { marketplace_attribute_value, platform_attribute_value } = attribute;

      return {
        marketplace_attribute_value,
        platform_attribute_value,
      };
    });

    Vue.set(state.toBinding.linked.attributes, 'values', allValues);
  },

  [Mutations.SAVE_ATTRIBUTES_VALUES_MARKETPLACE_FOR_BINDING](state, newValues: ApiBindingValue[]) {
    state.toBinding.linked.attributes.values = newValues;
  },

  [Mutations.SAVE_ATTRIBUTES_BINDING_IN_LINKED](state, attribute: CategoryAttribute) {
    state.toBinding.linked.attributes.marketplace_attribute = {
      ref: attribute.ref,
      name: attribute.name,
      label: attribute.label,
      type: attribute.type,
    };

    if (attribute.linked_attribute) {
      state.toBinding.linked.attributes.platform_attribute = {
        ref: attribute.linked_attribute.ref,
        name: attribute.linked_attribute.name,
        label: attribute.linked_attribute.label,
        type: attribute.linked_attribute.type,
      };
    }
  },

  [Mutations.UPDATE_ATTRIBUTES_MARKETPLACE_IN_BINDING](state) {
    const marketplaceAttributeLinked = state.toBinding.linked.attributes.marketplace_attribute;
    const platformAttributeLinked = state.toBinding.linked.attributes.platform_attribute;

    if (marketplaceAttributeLinked && platformAttributeLinked) {
      const index = state.attributes.marketplace.findIndex((attribute) => {
        return attribute.ref === marketplaceAttributeLinked.ref;
      });

      if (index != -1) {
        state.attributes.marketplace[index].linked_attribute = {
          ref: platformAttributeLinked.ref,
          name: platformAttributeLinked.name,
          label: platformAttributeLinked.label,
          type: platformAttributeLinked.type,
        };
      }
    }
  },

  [Mutations.SAVE_ATTRIBUTE_VALUE_MARKETPLACE_BY_INDEX](
    state,
    { attribute, index }: LinkAttributeMarketplaceValue
  ) {
    if (state.toBinding.linked.attributes.values) {
      let valueInNumber: number = attribute.value as number;

      switch (state.toBinding.linked.attributes.marketplace_attribute?.type) {
        case TypeAttributeBinding.SELECT:
          state.toBinding.linked.attributes.values[index].marketplace_attribute_value.value =
            attribute.value.toString();
          state.toBinding.linked.attributes.values[index].marketplace_attribute_value.ref =
            attribute.ref;
          state.toBinding.linked.attributes.values[index].marketplace_attribute_value.label =
            attribute.label;

          break;

        case TypeAttributeBinding.NUMBER_UNIT:
          if (typeof attribute.value === 'string') {
            valueInNumber = convertStringToFloat(attribute.value);
          }

          state.toBinding.linked.attributes.values[index].marketplace_attribute_value.value =
            valueInNumber;
          state.toBinding.linked.attributes.values[index].marketplace_attribute_value.ref = '';
          state.toBinding.linked.attributes.values[index].marketplace_attribute_value.label =
            attribute.value.toString();

          if (attribute.unit && attribute.unit.value) {
            state.toBinding.linked.attributes.values[index].marketplace_attribute_value.unit =
              attribute.unit.value.toString();
            state.toBinding.linked.attributes.values[
              index
            ].marketplace_attribute_value.unit_symbol = attribute.unit.label;
          }

          break;
      }
    }
  },

  [Mutations.SELECT_ATTRIBUTE_MARKETPLACE_IN_BINDIG](state, attribute: CategoryAttribute) {
    state.toBinding.marketplace.attribute = attribute;
  },

  [Mutations.CLEAR_ATTRIBUTES_BINDING](state) {
    state.toBinding.platform.attributes = [];
    state.toBinding.platform.attributesValues = [];
  },

  [Mutations.CLEAR_ATTRIBUTES_SELECTION](state) {
    state.toBinding.marketplace.attribute = null;
    state.toBinding.marketplace.attributesValues = [];
    state.toBinding.linked.attributes.marketplace_attribute = null;
    state.toBinding.linked.attributes.platform_attribute = null;
    Vue.set(state.toBinding.linked.attributes, 'values', []);
  },
};
