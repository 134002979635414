import { NavigationGuard } from 'vue-router/types/router';
import { Storage } from '@/components/storage';
import { PagesName } from '@/router/routes/pages-enum';

/**
 * Guarda de rota que decide a página inicial a carregar.
 */
export const loadInitialPage: NavigationGuard = async (to, from, next) => {
  try {
    const letsStartParams = Storage.get('letsStart');
    const params = Storage.get('paramsInitHub');

    Storage.set('letsStart', false);

    if (letsStartParams && params.marketplace) {
      return next({
        name: PagesName.WIZARD_AUTH,
        query: to.query,
      });
    }

    if (letsStartParams && !params.marketplace) {
      return next({
        name: PagesName.MARKEPLACES_LIST,
        query: to.query,
      });
    }

    if (!letsStartParams && !params.marketplace) {
      return next({
        name: PagesName.MARKEPLACES_LIST,
        query: to.query,
      });
    }

    return next({
      name: PagesName.LEGACY_PUBLISHED_OFFERS,
      params: { marketplace: params.marketplace },
      query: to.query,
    });
  } catch (e) {
    return next({ name: 'not-found' });
  }
};
