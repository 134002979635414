import Vue from 'vue';

import { Subject } from 'rxjs/internal/Subject';

import { Storage } from '@/components/storage';

import { ToggleBag } from '../toggle-bag/toggle-bag';
import {
  ToggleBag as ToggleBagContract,
  ToggleList,
  ToggleManager as ToggleManagerContract,
} from '../typing';

export class ToggleManager implements ToggleManagerContract {
  /**
   * Observable para as alterações que ocorrem nas toggles.
   *
   * @var {Subject<ToggleList>} subject
   * */
  protected subject: Subject<ToggleList> = new Subject();

  /**
   * Lista de toggles disponíveis.
   *
   * @var {ToggleList} toggleList
   * */
  protected toggleList: ToggleList = {};

  /**
   * ToggleManager's constructor.
   *
   * Atualizar README.md com novas toggles adicionadas.
   */
  constructor() {
    const toggles: Array<string> = [
      'hello-world',
      'new-flow-shopee',
      'offers-export',
      'bulk-pricing-rule',
    ];

    toggles.forEach((toggle) => {
      this.toggleList[toggle] = Storage.get(toggle, false);
    });

    this.update();
  }

  /**
   * @inheritdoc
   * */
  getSubject(): Subject<ToggleList> {
    return this.subject;
  }

  /**
   * Emite atualização do valor das toggles.
   *
   * @return {void}
   * */
  protected update(): void {
    this.subject.next(this.toggleList);
  }

  /**
   * {@inheritDoc}
   * */
  using(...toggles: Array<string>): Promise<ToggleBagContract> {
    return ToggleBag.from(this, ...toggles);
  }

  /**
   * {@inheritDoc}
   * */
  isEnabled(toggleName: string): boolean {
    const observer = Vue.observable<{ enabled: boolean }>({
      enabled: this.toggleList[toggleName],
    });

    this.subject.subscribe((value) => {
      observer.enabled = value[toggleName];
    });

    return observer.enabled;
  }

  /**
   * {@inheritDoc}
   * */
  enable(toggleName: string): void {
    if (this.toggleList[toggleName]) {
      return;
    }
    Storage.set(toggleName, true);
    this.toggleList[toggleName] = true;
    this.update();
  }

  /**
   * {@inheritDoc}
   * */
  disable(toggleName: string): void {
    if (!this.toggleList[toggleName]) {
      return;
    }
    Storage.set(toggleName, false);
    this.toggleList[toggleName] = false;
    this.update();
  }
}
