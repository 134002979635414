export type ConfigKey =
  | 'title'
  | 'env'
  | 'apiUrl'
  | 'apiMockUrl'
  | 'shopeeApiUrl'
  | 'trayIntegrationUrl'
  | 'bagyIntegrationUrl'
  | 'HUB_PUSHER_APP_KEY'
  | 'HUB_PUSHER_APP_CLUSTER'
  | 'appUrl'
  | 'newFlowShopeeMinimumStoreId'
  | 'urlFawkes';

const configurations: Record<ConfigKey, unknown> = {
  env: process.env.NODE_ENV,
  title: process.env.VUE_APP_TITLE,
  apiUrl: process.env.VUE_APP_API_URL,
  apiMockUrl: process.env.VUE_APP_API_MOCK_URL,
  shopeeApiUrl: process.env.VUE_APP_SHOPEE_API_URL,
  trayIntegrationUrl: process.env.VUE_APP_TRAY_INTEGRATION_URL,
  bagyIntegrationUrl: process.env.VUE_APP_BAGY_INTEGRATION_URL,
  HUB_PUSHER_APP_KEY: process.env.VUE_APP_HUB_PUSHER_APP_KEY,
  HUB_PUSHER_APP_CLUSTER: process.env.VUE_APP_HUB_PUSHER_APP_CLUSTER,
  appUrl: process.env.VUE_APP_URL,
  urlFawkes: process.env.VUE_APP_URL_FAWKES,
  newFlowShopeeMinimumStoreId: process.env.VUE_APP_NEW_FLOW_SHOPEE_MINIMUM_STORE_ID,
};

const config = (key?: ConfigKey, $default: unknown = null): any => {
  if (!key) {
    return configurations;
  }
  return configurations[key] ?? $default;
};

export default config;
