import { Module } from 'vuex';

import { RootState } from '../../types';
import { CategoriesState } from './types';
import { FiltersModule } from './filters';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const state: CategoriesState = {
  categories: {
    marketplace: {
      root: [],
      byRef: {},
    },
    list: {
      paging: {
        from: 1,
        links: {},
        number: 0,
        size: 25,
        to: 1,
        total: 0,
      },
      filters: {},
      content: [],
      loading: true,
    },
  },
  attributes: {
    marketplace: [],
  },
  toBinding: {
    platform: {
      category: {},
      attributes: [],
      allAttributes: [],
      attributesValues: [],
    },
    marketplace: {
      path: [],
      categories: [],
      selectedCategory: null,
      attribute: null,
      attributesValues: [],
    },
    isLoading: false,
    linked: {
      attributes: {
        platform_attribute: null,
        marketplace_attribute: null,
      },
      categories: {
        platform_category: null,
        marketplace_category: null,
      },
      changed: false,
    },
    error: null,
  },
  selectedCategoriesToOffers: [],
};

const namespaced = true;

export const categoriesModule: Module<CategoriesState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  modules: {
    filters: FiltersModule,
  },
};
