import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { AccountState } from './types';
import { Account } from '@/entities';
import { GetterAccount } from './enum';

export const getters: GetterTree<AccountState, RootState> = {
  /**
   * Retorna a lista de contas já carregadas.
   * */
  accounts(state): Account[] {
    return state.accounts;
  },

  /**
   * Retorna uma conta especifica.
   * */
  getAccount(state): (id: number) => Account | null {
    return (id: number) => {
      if (!state.accountById[id]) {
        return null;
      }

      return state.accountById[id];
    };
  },

  /**
   * Retorna as contas do marketplace.
   * */
  getMarketplaceAccounts(state): (marketplaceId: number) => Account[] {
    return (marketplaceId: number) => {
      return state.accounts.filter((account) => account.marketplace_id === marketplaceId);
    };
  },

  /**
   * Data de expiração da integração com a Shopee.
   */
  expirationDateShopee(state): string | null {
    return state.expirationDateShopee;
  },

  [GetterAccount.HAS_PRICING_ALERT](state): boolean {
    return state.hasPricingAlert;
  },

  [GetterAccount.PENDING_ACCOUNT](state): Account | null {
    return state.pendingAccount;
  },

  // @TODO: Implementar getters para plataforma.
};

export default getters;
