import store from '@/store';
import { ActionPricingRule } from '@/store/modules/pricing-rule/enum-actions';
import { MutationUiSettings } from '@/store/modules/ui-settings/enum';
import { NavigationGuard } from 'vue-router';
import Vue from 'vue';

/**
 * Guard de rota que obtem a regra de precificação atual se estiver em uma rota apta para
 * tal que não tenha o get para o endpoint de pricing, a action executada valida se a
 * conta do usuário possui regra de preço compatível com a nova experiência de regra de
 * precificação, permitindo a validação de exibição das funcionalidades nas páginas de
 * Criação de ofertas, anúncios Publicados e lista de Contas.
 */
export const pricingNewFlow: NavigationGuard = async (to, from, next) => {
  const storeId = store.getters['store/store']?.id;
  const accounts = store.getters['account/accounts'];
  const marketplace = store.getters['marketplace/activeMarketplace'];

  const notNeedLoadingPricing = !(storeId || accounts.length > 0 || marketplace);
  if (notNeedLoadingPricing) {
    return next();
  }

  const pricingRules = await store.dispatch(
    'pricingRule/' + ActionPricingRule.GET_CURRENT_ACCOUNT_PRICING_RULES_BY_ACCOUNT_ID,
    accounts[0].id
  );

  if (pricingRules && pricingRules.length > 0) {
    store.commit('uiSettings/' + MutationUiSettings.SET_PRICING_ACCOUNT, pricingRules[0]);
  }

  return next();
};
