import { Module } from 'vuex';
import { AccountState } from './types';
import { RootState } from '../../types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { FiltersModule } from './filters';

export const state: AccountState = {
  accounts: [],
  accountById: {},
  shouldSkipCheckPendingOrder: false,
  expirationDateShopee: null,
  hasPricingAlert: false,
  pendingAccount: null,
};

const namespaced = true;

export const accountModule: Module<AccountState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    filters: FiltersModule,
  },
};
