import { NavigationGuard } from 'vue-router/types/router';
import { UrlResolver } from '@/components/api/url-resolver';
import vuex from '@/store';
import { PayloadGetExists } from '@/store/modules/store/types';
import { ActionsStore } from '@/store/modules/store/enum';
import { Platform } from '@/store/modules/auth/types';
import { Storage } from '@/components/storage';
import { PagesName } from '@/router/routes/pages-enum';

// @TODO: Separar interface em arquivo separado, uma vez que é utilizado em 3 fluxos diferentes.
export interface QueryParamsInitialPage {
  store?: number;
  marketplace?: string;
  platform?: string;
  url?: string;
  active_theme?: string;
}

/**
 * Guarda de rota que garante a integração com a plataforma e decide a página
 * inicial a carregar.
 *
 * Valida se vai para landing page, ou se carrega a URL da plataforma integradora para
 * autenticação ou se vai para a lista de anúncios publicados.
 */
export const ValidatesIntegrationAndPageToLoad: NavigationGuard = async (to, from, next) => {
  try {
    const currentQuery: QueryParamsInitialPage = to.query;
    const platform = UrlResolver.getQueryPlatformByParams(currentQuery as Record<string, string>);

    Storage.set('paramsInitHub', currentQuery);

    const { url } = currentQuery;

    if (url) Storage.set('url', url);

    if (platform === 'tray') vuex.commit('auth/platform', Platform.TRAY);
    if (platform === 'bagy') vuex.commit('auth/platform', Platform.BAGY);

    const payloadGetExists: PayloadGetExists = {
      storeId: Number(currentQuery.store),
      platform,
    };

    const hasValidStore = await vuex.dispatch(
      'store/' + ActionsStore.CHECK_IF_EXISTS,
      payloadGetExists
    );

    if (!hasValidStore) {
      const marketplacesWithLandingPage: Array<string> = ['shopee', 'shein', 'amazon'];
      const notHasLandingPage = !marketplacesWithLandingPage.includes(
        to.query.marketplace as string
      );
      if (notHasLandingPage) {
        return next({
          name: PagesName.WIZARD_AUTH,
          params: {
            marketplace: to.query.marketplace as string,
          },
        });
      }

      // No fluxo onde é utilizado este guard, é necessário deixar o next vazio para não gerar um looping infinito
      return next();
    }

    const shouldAuthenticateWithPlatform = !vuex.getters.isAuthenticated;
    if (shouldAuthenticateWithPlatform) {
      window.location.href = UrlResolver.getAuthUrl();
      return;
    }

    const marketplace = (to.query.marketplace as string) ?? '';

    return next({
      name: PagesName.LEGACY_PUBLISHED_OFFERS,
      params: { marketplace },
    });
  } catch (e) {
    return next({ name: 'not-found' });
  }
};
