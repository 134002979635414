import { PagesName } from '@/router/routes/pages-enum';
import { NavigationGuard } from 'vue-router';
import { shouldLoadNewFlowByMarketplaceName } from './toggle-new-flow';

/**
 * Guard para validar multi contas para página de contas
 * @returns carrega a rota no fluxo novo se tiver habilitado, se não redireciona para a rota legada
 */
export const toggleMultiAccounts: NavigationGuard = async (to, from, next) => {
  const marketplaceName = to.params.marketplace;

  const isToLoadMultiAccounts = shouldLoadNewFlowByMarketplaceName(marketplaceName);
  const isRouteOfLegacyAccount = to.name === PagesName.LEGACY_ACCOUNTS;
  if (isRouteOfLegacyAccount && isToLoadMultiAccounts) {
    return next({
      name: PagesName.ACCOUNTS,
      params: { marketplace: to.params.marketplace },
    });
  }

  return next();
};
