import { ActionContext, ActionTree } from 'vuex';

import { RootState } from '@/store/types';
import { CreateOfferState, Item } from './types';

export const actions: ActionTree<CreateOfferState, RootState> = {
  /**
   * Insere o produto na Store.
   *
   * @param {{ commit }: ActionContext<OfferRelationState, RootState>}
   * @param {Array<Item>} items
   * @return {void}
   *
   * */
  setItems(
    { rootState, commit }: ActionContext<CreateOfferState, RootState>,
    items: Array<Item>
  ): void {
    commit('setItems', items);
    commit('setStoreId', rootState.store.store?.id);
  },

  /**
   * Define se a criação de ofertas está desabilitada.
   *
   * @param {{ commit }: ActionContext<CreateOfferState, RootState>}
   * @param {boolean} isDisabled
   * @return {void}
   *
   * */
  setDisabledCreation(
    { commit }: ActionContext<CreateOfferState, RootState>,
    isDisabled: boolean
  ): void {
    commit('setDisabledCreation', isDisabled);
  },
};

export default actions;
